export const environment = {
  production: true,
  defaultauth: 'fake-backend',
  apiUrl:'https://api.sprysales.pe/api',
  url:'https://api.sprysales.pe/',
  urlReporte:'https://api.sprysales.pe/posts',
  userProof:{cta:'',user:'',password:''},
  indexedDbConfig: {db:'spryposv1',version:12},
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  }
};

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>; 
    public currentUser: Observable<User>;
    public currentUserT: Observable<String>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))); 
        this.currentUser = this.currentUserSubject.asObservable(); 
    }
    public currentUserToken(){
        return localStorage.getItem('userToken');
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }
    
    login(credentials: any) {
        return this.http.post<any>(`${environment.apiUrl}/auth/login`, credentials, {
            headers: {
                Iddb: '002',
                IsDash: 'web',
            }
        },)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.User));
                    localStorage.setItem('MENU', JSON.stringify(user.Menus));
                    localStorage.setItem('userToken', JSON.stringify(user.userToken));
                    this.currentUserSubject.next(user.User); 
                
                return user;
            }));
        // return this.http.post(`${environment.apiUrl}/login`, credentials);
    }
    loginCta(cta: String) {
        // return this.http.post(`${environment.url}/login-cta.php`, {cta:cta});

        return this.http.post(`${environment.url}api/auth/login`, { cta: cta });
    }
    loginOld(email: string, password: string) {
        return this.http.post<any>(`/users/authenticate`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}

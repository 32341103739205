import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, isDevMode } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';


import { ExtrapagesModule } from './extrapages/extrapages.module';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendInterceptor } from './core/helpers/fake-backend';
import { ToastrModule } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { ServiceWorkerModule } from '@angular/service-worker';

import { FullCalendarModule } from '@fullcalendar/angular'; // Importa el módulo FullCalendar
import { NgSelectModule } from '@ng-select/ng-select';
import { LoaderInterceptor } from './core/interceptors';


//

defineLocale('es', esLocale); // Define el idioma español

if (environment.defaultauth === 'firebase') {
	initFirebaseBackend(environment.firebaseConfig);
}
else {
	// tslint:disable-next-line: no-unused-expression
	FakeBackendInterceptor;
}

const dbConfig: DBConfig = {
	name: environment.indexedDbConfig.db, // Nombre personalizado de la base de datos
	version:environment.indexedDbConfig.version,
	objectStoresMeta: [
		,{
			store: 'versionDB',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
			  { name: 'id', keypath: 'id', options: { unique: true } },
			  { name: 'name', keypath: 'name', options: { unique: false } },
			  { name: 'version', keypath: 'version', options: { unique: false } },
			  { name: 'idIndex', keypath: 'id', options: { unique: false, multiEntry: true } },
	  
			]
		  },
		{
			store: 'dataPos',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'id', keypath: 'id', options: { unique: true } },
				{ name: 'title', keypath: 'title', options: { unique: false } },
				{ name: 'data', keypath: 'data', options: { unique: false } }
			]
		},
		,{
			store: 'tb_maestro_data',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
			  { name: 'id', keypath: 'id', options: { unique: true } }, 
			  { name: 'data', keypath: 'data', options: { unique: false } },
			  { name: 'searchIndex', keypath: 'search', options: { unique: false, multiEntry: true } },
			  { name: 'idIndex', keypath: 'id', options: { unique: false, multiEntry: true } },
	  
			]
		  },
		{
			store: 'pendInvoices',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'id', keypath: 'id', options: { unique: true } },
				{ name: 'title', keypath: 'title', options: { unique: false } },
				{ name: 'data', keypath: 'data', options: { unique: false } }
			]
		}, {
			store: 'cliente',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'id', keypath: 'id', options: { unique: true } },
				{ name: 'name', keypath: 'name', options: { unique: false } },
				{ name: 'ruc', keypath: 'ruc', options: { unique: false } },
				{ name: 'search', keypath: 'search', options: { unique: false } },
				{ name: 'data', keypath: 'data', options: { unique: false } },
				{ name: 'searchIndex', keypath: 'search', options: { unique: false, multiEntry: true } },
				{ name: 'idIndex', keypath: 'id', options: { unique: false, multiEntry: true } },
				{ name: 'rucIndex', keypath: 'ruc', options: { unique: false, multiEntry: true } },
			]
		}, {
			store: 'ubigeoDB',
			storeConfig: { keyPath: 'ubi_ubigeo', autoIncrement: false },
			storeSchema: [
				{ name: 'ubi_ubigeo', keypath: 'ubi_ubigeo', options: { unique: true } },
				{ name: 'ubi_depa', keypath: 'ubi_depa', options: { unique: false } },
				{ name: 'ubi_prov', keypath: 'ubi_prov', options: { unique: false } },
				{ name: 'ubi_dist', keypath: 'ubi_dist', options: { unique: false } },
				{ name: 'ubi_search', keypath: 'ubi_search', options: { unique: false } },
				{ name: 'ubi_searchIndex', keypath: 'ubi_search', options: { unique: false, multiEntry: true } },
			]
		}, {
			store: 'producto',
			storeConfig: { keyPath: 'id', autoIncrement: false },
			storeSchema: [
				{ name: 'id', keypath: 'id', options: { unique: true } },
				{ name: 'name', keypath: 'name', options: { unique: false } },
				{ name: 'precio', keypath: 'precio', options: { unique: false } },
				{ name: 'codigo', keypath: 'codigo', options: { unique: false } },
				{ name: 'search', keypath: 'search', options: { unique: false } },
				{ name: 'und', keypath: 'und', options: { unique: false } },
				{ name: 'data', keypath: 'data', options: { unique: false } },
				{ name: 'searchIndex', keypath: 'search', options: { unique: false, multiEntry: true } }
			]
		}
	]
};
export function createTranslateLoader(http: HttpClient): any {
	return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		FullCalendarModule, // Agregando FullCalendarModule
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		LayoutsModule,
		AppRoutingModule,
		ExtrapagesModule,
		CarouselModule,
		AccordionModule.forRoot(),
		TabsModule.forRoot(),
		TooltipModule.forRoot(),
		ScrollToModule.forRoot(),
		ToastrModule.forRoot(),
		NgxIndexedDBModule.forRoot(dbConfig),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: !isDevMode(),
			registrationStrategy: 'registerWhenStable:50000'
		}),
		NgSelectModule
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		// LoaderService,
		// { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
	],
})
export class AppModule {
	constructor(private localeService: BsLocaleService) {
		this.localeService.use('es'); // Establece el idioma español globalmente
	}
}

/** */

import { Component , OnInit} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import Swal from 'sweetalert2';
import { DatabaseService } from './shared/indexed-db/database.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  currentVersion = ''; 
  _swl = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger ms-2'
    },
    buttonsStyling: false
  });
  constructor(private databaseService: DatabaseService,private swUpdate: SwUpdate) { 
    if (this.swUpdate.isEnabled) {
      console.log('NO I')
      this.swUpdate.versionUpdates.subscribe((event) => {
      console.log('NO 2')

        if (event.type === 'VERSION_READY') {
      console.log('NO 3')

          const newVersion = event.latestVersion.hash;
          if (this.currentVersion !== newVersion) {
      console.log('NO 4')

            
      this._swl
      .fire({
        title: 'AVISO!',
        text: 'Una nueva versión está disponible. ¿Quieres actualizar ahora?',
        icon: 'info',
        confirmButtonText: 'Actualizar',
        cancelButtonText: 'Mas tarde!',
        showCancelButton: true
      })
      .then(result => {
        if (result.value) {
          this.currentVersion = newVersion;
          localStorage.setItem('appVersion', newVersion);
        
          this.activateUpdate();
        }
      });
    }
         
        }
      });
    }

  }
  ngOnInit() {
    // Aquí puedes realizar operaciones con la base de datos
    this.databaseService.checkDatabaseUpgrade();
  }
  activateUpdate() {
    navigator.serviceWorker.controller?.postMessage({ action: 'SKIP_WAITING' });
      setTimeout(() => window.location.reload(), 500);
  }
}

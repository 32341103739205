import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { NgxIndexedDBService } from 'ngx-indexed-db'; 
@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private dbName =environment.indexedDbConfig.db;
  
  private tableName = 'pendInvoices';

  constructor(private dbService: NgxIndexedDBService) {}

  async getTabsData() {
    return this.dbService.getAll('pendInvoices'); 
    // try { 
    //   return await this.dbService.getAll(this.tableName);
    // } catch (error) {
    //   return null;
    // }
  }
  async updateTabsData(tab: any) {
    if(typeof tab.form!== "undefined"){
      delete tab.form; 
    } 
    let taps={title:tab.title,id:tab.id,data:tab};
    console.log(taps)
    this.dbService.update('pendInvoices', taps)
    .subscribe((storeData) => {
      console.log('storeData: ', storeData);
    });
     
  }
  async deleteTabsData(item:number){
    this.dbService.delete(this.tableName, item).subscribe((allPeople) => {
      console.log('all people:', allPeople);
    });
  }
  async saveTabsData(iten:number,tabs: any) {
    if(typeof tabs.form!== "undefined"){
   //   delete tabs.form;
    }
    console.log(tabs)
    //this.dbService.clear(this.tableName).subscribe((allPeople) => {}); 
    this.dbService.getByKey(this.tableName, iten).subscribe((people) => {
      if(typeof people=== "undefined"){
        this.dbService.add(this.tableName,tabs).subscribe((key) => { });
      }else{
        console.log(tabs)
        this.dbService.update(this.tableName,tabs).subscribe((key) => { });
      } 
    });
    //
    
  }
  //cliente
  async getAllCliente() {
    return this.dbService.getAll('cliente'); 
  }

  async getByIndexCliente(index,ruc) {
    return this.dbService.getByIndex('cliente',index, ruc); 
  }
  async countClienteData(){
   return  this.dbService.count('cliente');
  }
  async addClienteData(cliente: any) {
    this.dbService.add('cliente', cliente).subscribe((key) => {});
  }
  async bulkAddClienteData(cliente: any[]) {
    this.dbService.bulkAdd('cliente', cliente).subscribe((key) => {});
  }
  async clearClienteData() {
    this.dbService.clear('cliente').subscribe((successDeleted) => {});
  }
  async updateClienteData(cliente: any) {
    this.dbService.update('cliente',cliente).subscribe((storeData) => {});
  }

  ///producto
  async getByIndexProducto(index,ruc) {
    return this.dbService.getByIndex('producto',index, ruc); 
  }
  async countProductoData(){
    return  this.dbService.count('producto');
  }
  async getAllProducto() {
    return this.dbService.getAll('producto'); 
  }
  async updateDataProducto(producto: any) {
    this.dbService.update('producto',producto).subscribe((storeData) => {});
  }
  async addProductoData(producto: any) {
    this.dbService.add('producto', producto).subscribe((key) => {});
  }
  async bulkAddProductoData(producto: any[]) {
    this.dbService.bulkAdd('producto', producto).subscribe((key) => {});
  }
  async clearProductoData() {
    this.dbService.clear('producto').subscribe((successDeleted) => {console.log('success? ', successDeleted); });
  }
  async clearDB() {
    this.dbService.clear('cliente').subscribe({
      next: () => {},
      error: (error) => {},
    });
    this.dbService.clear('producto').subscribe({
      next: () => {},
      error: (error) => {},
    });
    this.dbService.clear('catalogosunat').subscribe({
      next: () => {},
      error: (error) =>{},
    });
    this.dbService.clear('dataPos').subscribe({
      next: () => {},
      error: (error) =>{},
    });
     
    this.dbService.clear('ubigeoDB').subscribe({
      next: () => {},
      error: (error) => {},
    });
    this.dbService.clear('paisDB').subscribe({
      next: () => {},
      error: (error) => {},
    });
    this.dbService.clear('tb_maestro_data').subscribe({
      next: () => {},
      error: (error) => {},
    });
  }
  //guardar ubigeo
  async countUbigeoData(){
    return  this.dbService.count('ubigeoDB');
  }
  async bulkAddUbigeoData(cliente: any[]) {
    cliente.forEach((data) => {
      this.dbService.add('ubigeoDB', data)
        .subscribe({
          next: (key) => console.log('Dato añadido:', key),
          error: (err) => console.error('Error al añadir objeto:', data, err)
        });
    });
    
    // this.dbService
    // .bulkAdd('ubigeoDB', cliente)
    // .subscribe((key) => {
    // });
  }
  async getAllUbigeoData() {
    return this.dbService.getAll('ubigeoDB'); 
  }
//dataPos
  async addPosData(data: any,iten:string) {
    this.dbService.getByKey('dataPos', iten).subscribe((people) => {
      if(typeof people=== "undefined"){
        this.dbService.add('dataPos', data).subscribe((key) => {});
      }else{ 
        this.dbService.update('dataPos',data).subscribe((key) => { });
      } 
    });
  }
  async countPosData(){
    return  this.dbService.count('dataPos');
  }
  async getAllPosData() {
    return this.dbService.getAll('dataPos'); 
  }



  async addMaestroData(data: any,iten:string) {
    this.dbService.getByKey('tb_maestro_data', iten).subscribe((people) => {
      if(typeof people=== "undefined"){
        this.dbService.add('tb_maestro_data', data).subscribe((key) => {});
      }else{ 
        this.dbService.update('tb_maestro_data',data).subscribe((key) => { });
      } 
    });
  }
  async countMaestroData(){
    return  this.dbService.count('tb_maestro_data');
  
  }
  async getAllMaestroData() {
    return this.dbService.getAll('tb_maestro_data'); 
  }


}
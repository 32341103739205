<div class="container-fluid p-0">
    <div class="row g-0">

        <div class="col-xl-9">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">

                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <h4 class="mb-3"><i class="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i><span class="text-primary">5k</span>+ Satisfied clients</h4>

                                        <div dir="ltr">
                                            <owl-carousel-o class="owl-theme auth-review-carousel" [options]="carouselOption">
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">" Fantastic theme with a
                                                                ton of
                                                                options. If you just want the HTML to integrate with
                                                                your project, then this is the package. You can find
                                                                the
                                                                files in the 'dist' folder...no need to install git
                                                                and
                                                                all the other stuff the documentation talks about. "
                                                            </p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">Abs1981</h4>
                                                                <p class="font-size-14 mb-0">- Skote User</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <p class="font-size-16 mb-4">" If Every Vendor on Envato
                                                                are
                                                                as supportive as Themesbrand, Development with be a
                                                                nice
                                                                experience. You guys are Wonderful. Keep us the good
                                                                work. "</p>

                                                            <div>
                                                                <h4 class="font-size-16 text-primary">nezerious</h4>
                                                                <p class="font-size-14 mb-0">- Skote User</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
            <div class="auth-full-page-content p-md-5 p-4">
                <div class="w-100">

                    <div class="d-flex flex-column h-100">
                        <div class="mb-4 mb-md-5">
                            <a routerLink="/" class="d-block card-logo">
                                <img src="assets/images/logo-dark.png" alt="" height="18" class="card-logo-dark">
                                <img src="assets/images/logo-light.png" alt="" height="18" class="card-logo-light">
                            </a>
                        </div>
                        <div class="my-auto">

                            <div>
                                <h5 class="text-primary">Bienvenido !</h5>
                                <p class="text-muted">Inicia sesión para continuar con SPRY.</p>
                            </div> 
                            <div class="mt-4">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="mb-3">
                                        <label for="cta">Cuenta</label>
                                        <input type="cta" class="form-control" id="cta" formControlName="cta" [ngClass]="{ 'is-invalid': submitted && f.cta.errors }" 
                                        placeholder="Enter Cuenta">
                                        <div *ngIf="submitted && f.cta.errors" >
                                            <div *ngIf="f.cta.errors.required">Cuenta is required</div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label for="email">Usuario</label>
                                        <input type="email" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter email">
                                        <div *ngIf="submitted && f.email.errors" >
                                            <div *ngIf="f.email.errors.required">Usuario is required</div>
                                            
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <div class="float-end">
                                            <!-- <a routerLink="/account/recoverpwd-2" class="text-muted">Forgot  password?</a> -->
                                        </div>
                                        <label for="userpassword">Contraseña</label>
                                        <div class="input-group auth-pass-inputgroup">
                                            <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon">
                                            <button class="btn btn-light " type="button" id="password-addon"><i class="mdi mdi-eye-outline"></i></button>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <span *ngIf="f.password.errors.required">Contraseña is required</span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div class="mt-3 d-grid">
                                        <button class="btn btn-primary btn-block" type="submit">Log In</button>
                                    </div> 

                                </form>
                                 
                            </div>
                        </div>

                        <div class="mt-4 mt-md-5 text-center">
                            <p class="mb-0">©
                                {{year}} Skote. Crafted with <i class="mdi mdi-heart text-danger"></i> by
                                Themesbrand</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>
<!-- end container-fluid -->
import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/auth.models';
import { environment } from '../../../environments/environment';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    private apiUrl =environment.apiUrl ; 
    constructor(private http: HttpClient) {}

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
   /* login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }*/
    login(credentials: any) {
        return this.http.post(`${this.apiUrl}/login`, credentials);
    }
    loginCta(cta: String) {
        return this.http.post(`${environment.url}/login-web.php`, {cta:cta});
    }
    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
        getFirebaseBackend().logout();
    }
}


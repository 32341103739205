import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { environment } from '../../../environments/environment';
import { UtilityService } from '../../shared/ui/utility.service';
@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  dbName = environment.indexedDbConfig.db;
  currentVersion = environment.indexedDbConfig.version; // Versión actual de la base de datos

  constructor(private dbService: NgxIndexedDBService,private utilityService: UtilityService,) {
  //  this.initializeDatabase(); 
      
  }
  checkDatabaseUpgrade() {
    // this.dbService.clear('versionDB').subscribe({
    //     next: () => {
            this.dbService.getByKey('versionDB', 1).subscribe((storedVersion: any) => {
                if (!storedVersion) {
                  // No existe ningún dato en la tabla `versionDB`, se inserta la versión inicial
                  this.dbService.add('versionDB', { id: 1, version: this.currentVersion }).subscribe({
                    next: () => console.log(`Versión inicial de la base de datos establecida: ${this.currentVersion}`),
                    error: (err) => console.error('Error al establecer la versión inicial:', err)
                  });
                } else if (storedVersion.version !== this.currentVersion) {
                  // Actualizar si la versión almacenada es diferente a la actual
                  console.log(`Actualizando la versión de la base de datos de ${storedVersion.version} a ${this.currentVersion}`);
                  this.dbService.update('versionDB', { id: 1, version: this.currentVersion }).subscribe({
                    next: () => console.log('Versión de la base de datos actualizada exitosamente.'),
                    error: (err) => console.error('Error al actualizar la versión:', err)
                  });
                    this.loadData();
                  // Aquí puedes agregar lógica de migración si es necesario
                } else {
                  console.log(`La versión de la base de datos ya está actualizada: ${this.currentVersion}`);
                }
              }); 
    //     },
    //     error: (error) => console.error(`Error al limpiar la tabla cliente:`, error),
    //   });
   
  }

   
  loadData() {  
    this.utilityService.clearDB();
    this.utilityService.getAllUbigeo(); 
  }
}
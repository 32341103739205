import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
 
import { environment } from '../../../environments/environment';
import { IndexedDbService } from '../../shared/indexed-db/indexed-db.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
@Injectable({ providedIn: 'root' })
export class UtilityService {
    currentUser:any;
    constructor(private http: HttpClient,
        private authfackservice: AuthfakeauthenticationService,
        private indexedDbService:IndexedDbService) { 
            this.currentUser = this.authfackservice.currentUserValue;
    }
    clearDB(){
        this.indexedDbService.clearDB();
      }
    async getAllUbigeo() {
        (await this.indexedDbService.countUbigeoData()).subscribe(async result => { 
            console.log(result)
            if(result<=0){
                this.http.get(`${environment.apiUrl}/storage`).subscribe({
                    next: (response: any) => { 
                        if(response.success===true){
                            this.indexedDbService.bulkAddUbigeoData(response.ubigeo);
                            this.indexedDbService.addMaestroData({id:'tipoDocumento',data:response.tipoDocumento},'tipoDocumento');
                            this.indexedDbService.addMaestroData({id:'zona',data:response.zona},'zona');
                            this.indexedDbService.addMaestroData({id:'listPrecio',data:response.listPrecio},'listPrecio');
                            this.indexedDbService.addMaestroData({id:'listPredeterm',data:response.listPredeterm},'listPredeterm');
                            this.indexedDbService.addMaestroData({id:'categoria',data:response.categoria},'categoria');
                            this.indexedDbService.addMaestroData({id:'comprobante_default',data:response.comprobante_default},'comprobante_default');
                            this.indexedDbService.addMaestroData({id:'medio_pago',data:response.medio_pago},'medio_pago');
                            this.indexedDbService.addMaestroData({id:'frecuencia_visit',data:response.frecuencia_visit},'frecuencia_visit');
                            this.indexedDbService.addMaestroData({id:'esquema_facturac',data:response.esquema_facturac},'esquema_facturac');
                            this.indexedDbService.addMaestroData({id:'row_clasificacion',data:response.row_clasificacion},'row_clasificacion');                            
                            this.indexedDbService.addMaestroData({id:'row_tip_afectacion_producto',data:response.row_tip_afectacion_producto},'row_tip_afectacion_producto');
                            this.indexedDbService.addMaestroData({id:'row_lista_producto_codsunat',data:response.row_lista_producto_codsunat},'row_lista_producto_codsunat');
                            this.indexedDbService.addMaestroData({id:'row_tipo_producto',data:response.row_tipo_producto},'row_tipo_producto');
                            this.indexedDbService.addMaestroData({id:'row_und_medida',data:response.row_und_medida},'row_und_medida');
                            this.indexedDbService.addMaestroData({id:'row_moneda',data:response.row_moneda},'row_moneda'); 
                            this.indexedDbService.addMaestroData({id:'row_tipo_cliente',data:response.row_tipo_cliente},'row_tipo_cliente');
                            this.indexedDbService.addMaestroData({id:'row_canal_venta',data:response.row_canal_venta},'row_canal_venta');
                             
                            
                        }
                    },
                    error: (error:any) =>{ 
                    },
                    complete: () => {} 
                }); 

            } 
        });
       // this.indexedDbService.getAllUbigeoData();
        //return this.http.post(`${environment.apiUrl}/data-filter`, data);
    }
}